
body {
  word-wrap: break-word;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin: 0;
  padding: 0;
  font-family: '微软雅黑', Arial, sans-serif;
}


.video {
  max-width: 800px;
}

#react-content {
  min-height: 100%;
}
.page-base-wrapper p {
  padding: 0;
  margin: 0;
}

.page-base-wrapper{
  width: 100%;
  position: relative;
  //overflow: hidden;
  background-color: #fafafa;
  &-content{
    height: 100%;
    max-width: 1200px;
    position: relative;
    //position: static;
    margin: auto;
    will-change: transform;
  }
}